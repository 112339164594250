import Modal from 'react-bootstrap/Modal';


function Impressum(props) {
    return (
        <>
        <Modal
        show={props.show}
        onHide={() => props.setShow(false)}
        dialogClassName="modal-80w"
        aria-labelledby="example-modal-sizes-title-sm">
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-sm">
            Impressum
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <div className="overflow-auto" style={{'max-height': '90vw'}}>
                <h4>Website Name: WatchSage</h4>
                <p>Website URL: www.watchsage.com</p>

                <h4>Responsible Party:</h4>
                <p>
                    Christian Bock<br/>
                    Goethestr. 21<br/>
                    68723 Schwetzingen<br/>
                    Germany<br/>
                    christian [at] watchsage [dot] com<br/>
                    0041775347138
                </p>

                <h4>Legal Disclaimer:</h4>
                <p>
                    The information provided on this website is for general informational purposes only. While we strive to keep
                    the information up to date and accurate, we make no representations or warranties of any kind, express or
                    implied, about the completeness, accuracy, reliability, suitability, or availability with respect to the
                    website or the information, products, services, or related graphics contained on the website for any purpose.
                    Any reliance you place on such information is therefore strictly at your own risk.
                    <br/>
                    In no event will we be liable for any loss or damage including without limitation, indirect or consequential
                    loss or damage, or any loss or damage whatsoever arising from loss of data or profits arising out of, or in
                    connection with, the use of this website.
                    <br/>
                    Through this website, you may be able to link to other websites that are not under our control. We have no
                    control over the nature, content, and availability of those sites. The inclusion of any links does not
                    necessarily imply a recommendation or endorse the views expressed within them.
                    <br/>
                    Every effort is made to keep the website up and running smoothly. However, we take no responsibility for,
                    and will not be liable for, the website being temporarily unavailable due to technical issues beyond our
                    control.
                </p>

                <h4>Copyright Notice:</h4>
                <p>
                    This website and its content are copyright of Christian Bock. All rights reserved. Any redistribution or
                    reproduction of part or all of the contents in any form is prohibited other than the following:
                    <br/>
                    - You may print or download to a local hard disk extracts for your personal and non-commercial use only.
                    <br/>
                    - You may copy the content to individual third parties for their personal use, but only if you acknowledge
                    the website as the source of the material.
                    <br/>
                    You may not, except with our express written permission, distribute or commercially exploit the content. Nor
                    may you transmit it or store it in any other website or other form of electronic retrieval system.
                </p>

                <h4>Privacy Policy:</h4>
                <p>
                    For information on how we handle your personal data and your rights under data protection laws, please refer
                    to our Privacy Policy.
                </p>
            </div>
        </Modal.Body>
        </Modal>
        </>
    )
}

export default Impressum