import * as React from "react";
const SVGComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="120px"
    height="66.11px"
    viewBox="0 0 150 110"
    {...props}
  >
    <path
      fillRule="evenodd"
      fill="#fff"
      d="M136.221 13.832a5.132 5.132 0 0 1 0.388 1.961c0 2.843 -2.305 5.148 -5.148 5.148a5.125 5.125 0 0 1 -3.258 -1.163L76.24 66.838a10.017 10.017 0 0 1 1.773 5.705c0 5.558 -4.506 10.063 -10.064 10.063 -5.558 0 -10.063 -4.506 -10.063 -10.063a10.02 10.02 0 0 1 1.08 -4.539L25.007 43.949c-1.12 1.502 -2.91 2.475 -4.928 2.475 -3.392 0 -6.143 -2.75 -6.143 -6.143a6.138 6.138 0 0 1 0.266 -1.79L0.02 26.753l16.732 8.366a6.112 6.112 0 0 1 3.328 -0.981c3.392 0 6.143 2.75 6.143 6.143a6.129 6.129 0 0 1 -0.345 2.03l34.938 23.134c1.823 -1.832 4.346 -2.966 7.134 -2.966 2.676 0 5.107 1.045 6.91 2.748L127.231 18.728a5.122 5.122 0 0 1 -0.92 -2.935c0 -2.843 2.305 -5.148 5.148 -5.148a5.125 5.125 0 0 1 3.072 1.017L149.968 0.084l-13.748 13.748ZM19.392 35.307c-2.362 0 -4.277 1.915 -4.277 4.277s1.915 4.277 4.277 4.277 4.277 -1.915 4.277 -4.277 -1.915 -4.277 -4.277 -4.277Zm112.964 -22.643c-1.667 0 -3.019 1.352 -3.019 3.019s1.352 3.019 3.019 3.019 3.019 -1.352 3.019 -3.019 -1.352 -3.019 -3.019 -3.019ZM68.723 49.396c-30.462 0 -36.499 -30.563 -36.499 -33.021 0 -0.119 -0.09 -1.621 1.674 -1.621s1.849 1.621 1.849 1.621 1.766 11.155 13.328 11.155c8.977 0 12.052 -8.698 14.489 -8.698 2.436 0 1.885 1.93 5.161 1.93 3.276 0 2.461 -1.93 5.018 -1.93 2.557 0 6.051 8.698 14.376 8.698 10.791 0 13.545 -8.698 13.545 -11.155 0.003 -0.134 0.089 -1.621 1.771 -1.621 1.682 0 1.751 1.51 1.751 1.621 0 2.457 -6 33.021 -36.462 33.021Z"
    />
    <text
      kerning="auto"
      fontFamily="Myriad Pro"
      fill="#fff"
      fontSize="20px"
      x="25%"
      y="92%"
    >
      <tspan fontSize="24px" fontFamily="EB Garamond" fill="#fff" className='justText'>
        {"WatchSage"}
      </tspan>
    </text>
  </svg>
);
export default SVGComponent;
