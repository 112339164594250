import { useState, Fragment } from 'react';
import Form from 'react-bootstrap/Form';
// Import as a module in your JS
import 'react-bootstrap-typeahead/css/Typeahead.css';
import { Typeahead, Highlighter, MenuItem, Menu } from 'react-bootstrap-typeahead';

function SearchBar(props) {

  const [singleSelections, setSingleSelections] = useState([]);
  var options = props.data;

  const onSelect = (selected) => {
    setSingleSelections(selected);
    console.log(selected[0])
    props.selectionFunc(selected[0]);
  }

  const filterByCallback = (option, props) => {
    let has_reference = option.reference_number.toLowerCase().indexOf(props.text.toLowerCase()) !== -1;
    let has_name = option.name.toLowerCase().split('/')[0].indexOf(props.text.toLowerCase()) !== -1;
    let has_brand = -1;

    if (option.obj_type === 'watch'){
      has_brand = option.brand.name.toLowerCase().indexOf(props.text.toLowerCase()) !== -1;
    }
    else {
      has_brand = option.brand.toLowerCase().indexOf(props.text.toLowerCase()) !== -1;
    }
    return has_reference || has_name || has_brand;
  }
    
  var groupBy = function(xs, key) {
    return xs.reduce(function(rv, x) {
      (rv[x[key]] = rv[x[key]] || []).push(x);
      return rv;
    }, {});
  };

  return (
    <>
      <Form.Group className="searchBarGroup">
        <Typeahead
          filterBy={filterByCallback}
          id="basic-typeahead-single"
          labelKey={option => option.name.split('/')[0]}
          onChange={onSelect}
          options={options}
          placeholder="Search a Watch or Caliber..."
          selected={singleSelections}
          minLength={3}
          renderMenu={(
            results,
            {
              newSelectionPrefix,
              paginationText,
              renderMenuItemChildren,
              ...menuProps
            },
            state
            ) => {
              let index = 0;
              const obj_types = groupBy(results, 'obj_type');
              const items = Object.keys(obj_types)
                .sort()
                .map((obj_type) => (
                  <Fragment key={obj_type}>
                    {index !== 0 && <Menu.Divider />}
                    <Menu.Header>{obj_type === 'watch' ? 'Watches' : 'Calibers'}</Menu.Header>
                    {obj_types[obj_type].map((option) => {
                      const item = (
                        //<MenuItem key={index} option={option} position={index} onMouseOver={() => {props.highlightFunc(option)}} onMouseLeave={() => {props.highlightFunc(null)}}>
                        <MenuItem key={index} option={option} position={index}>
                          <Highlighter className="watchHighlighter" search={state.text}>{obj_type === 'watch' ? option.name.split('/')[0] : option.reference_number}</Highlighter>
                          <div>
                            <small>
                              <Highlighter className="watchHighlighter" search={state.text}>{(option.obj_type === 'watch' ? option.brand.name + " | " + option.reference_number : option.brand) }
                              </Highlighter>
                            </small>
                          </div>
                        </MenuItem>
                      );

                      index += 1;
                      return item;
                    })}
                  </Fragment>
                ));

              return <Menu {...menuProps}>{items}</Menu>;
            }
          }
        />
      </Form.Group>
    </>
  );
}

export default SearchBar;