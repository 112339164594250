import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ProgressBar from 'react-bootstrap/ProgressBar';
import data_completeness from './data_completeness.json';
import { useState } from 'react';

import Impressum from './Impressum';
import PrivacyPolicy from './PrivacyPolicy';


function Footer(props) {
	const [impressumShow, setImpressumShow] = useState(false);
	const [privacyPolicyShow, setPrivacyPolicyShow] = useState(false);


	const watch_progress_label = data_completeness['num_watches_exc'] + '/' + data_completeness['num_watches_wb'] + ' watches';
	const cal_progress_label = data_completeness['num_calibers_exc'] + '/' + data_completeness['num_calibers_wb'] + ' calibers';
	return (
		<>
		<Impressum show={impressumShow} setShow={setImpressumShow}/>
		<PrivacyPolicy show={privacyPolicyShow} setShow={setPrivacyPolicyShow}/>
		 <Row className="m-0">
        	<Col md={{span: 3, offset: 3}} className="footer-col pb-2 justify-content-center">
        		<div align="center">
        			<span className="fw-light panelSubtitle onDark">{watch_progress_label}</span>
        		</div>
        		<ProgressBar striped variant="success" now={data_completeness['frac_watches'] * 100}/>
        	</Col>
        	<Col md={{span: 3}} className="footer-col pb-2 justify-content-center">
	        	<div align="center">
	    			<span className="fw-light panelSubtitle onDark">{cal_progress_label}</span>
	    		</div>
        		<ProgressBar striped variant="success" now={data_completeness['frac_calibers'] * 100}/>
        	</Col>
        </Row>
    	<Row className="m-0">
          <Col md={12} className="footer-col d-flex p-2 justify-content-center">
            <span className="fw-light panelSubtitle onDark">version: 1.0.0-a.1{String.fromCharCode(8192)}|{String.fromCharCode(8192)}</span>
            <a onClick={() => setImpressumShow(true)} className="fw-light panelSubtitle link onDark">
	         Impressum
	      </a>
	      <span className="fw-light panelSubtitle onDark">{String.fromCharCode(8192)}|{String.fromCharCode(8192)}</span>
	      <a onClick={() => setPrivacyPolicyShow(true)} className="fw-light panelSubtitle link onDark">
	         Privacy Policy
	      </a>
          </Col>
        </Row>
      </>
	)
}

export default Footer