//import logo from './logo.svg';
import './App.css';
import { useEffect } from "react";
import Container from 'react-bootstrap/Container';
import CookieConsent, { 
  getCookieConsentValue,
  Cookies 
} from "react-cookie-consent";
import { initGA } from "./ga-utils";

import WatchGraph from './WatchGraph';
import Footer from './Footer';

function App() {
  const handleAcceptCookie = () => {
    if (process.env.REACT_APP_GOOGLE_ANALYTICS_ID) {
      initGA(process.env.REACT_APP_GOOGLE_ANALYTICS_ID);
    }
  };

  const handleDeclineCookie = () => {
    //remove google analytics cookies
    Cookies.remove("_ga");
    Cookies.remove("_gat");
    Cookies.remove("_gid");
  };

  useEffect(() => {
      const isConsent = getCookieConsentValue();
      if (isConsent === "true") {
        handleAcceptCookie();
      }
    }, []);


  return (
      <Container fluid className='app'>
        <WatchGraph/>
        <Footer/>
        <CookieConsent enableDeclineButton
          disableStyles={true}
          overlay={true}
          location={"bottom"}
          onAccept={handleAcceptCookie}
          onDecline={handleDeclineCookie}
          expires={150}
          cookieName="exCaliberCookie"
          declineButtonClasses="btn btn-decline btn-sm"
          buttonClasses="btn btn-accept btn-sm m-2"
          buttonWrapperClasses="m-0"
          contentClasses="m-0 ps-2 pe-2"
          style={{
            alignItems: "baseline",
            background: "#333333",
            color: "white",
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "center",
            left: "0",
            position: "fixed",
            width: "100%",
            zIndex: "999",
          }}
          contentStyle={{
            fontFamily: 'Cormorant Garamond',
            fontWeight: 'light',
          }}
          overlayStyle={{
            position: "fixed",
            left: 0,
            top: 0,
            width: "100%",
            height: "100%",
            zIndex: "999",
            backgroundColor: "rgba(0,0,0,0.3)",
          }}
          >
          This website uses cookies to enhance the user experience.
        </CookieConsent>
      </Container>
  );
}

export default App;
