import PanelHeader from './PanelHeader';
import PanelContent from './PanelContent';

function WatchPanel(props) {

	function highlightNodes() {
		let selectedNode = props.selectedNode.values().next().value;
		let selBrandName = selectedNode.obj_type === 'watch' ? selectedNode.brand.name : selectedNode.brand;
		let toHighlight = props.graphData.nodes.filter(elem => elem.brand.name === selBrandName | elem.brand === selBrandName);
		for (const node of toHighlight) { 
			props.highlightNodes.add(node);
		}
	}

	function resetHighlight(){
		props.highlightNodes.clear();
	}

	const getInitPrompt = () => {
		return (
			<div className="watchSageFont">
				<div className="initMessage mt-3">
					<h4 style={{"marginBottom": "0.25rem"}}>Welcome to WatchSage!</h4>
					<p>
						The WatchSage network lets you explore the relationships of watches and their movements.
					</p>

					<span className="selectNode">To get started, you can</span>
				</div>
				<div className="mt-2 bulletList">
					<ul>
						<li>
							Search for a watch or caliber in the search bar above,
						</li>
						<li>
							select a caliber from the network to learn more about it and the watches using it,
						</li>
						<li>
							and select your favorite manufacture(s) to spot their timepieces faster.
						</li>
					</ul>
				</div>
				<h5> How to read the network </h5>
				<p>
					Edges illustrate the parent-child relationship. For example, the ETA 2824-2 is the base movement 
					of many movements. Try searching for "2824-2" in the search bar.
				</p>
				<h5> How many watches and movements are in the WS network? </h5>
				<p>
					Currently, there is only a limited number of watches and movements available. Acquiring the data is not
					free, but I hope to add more data points in the future.
				</p>
			</div>
		)
	}

	const getPanelContent = () => {
		if (props.selectedNode.size > 0){
			const setIter = props.selectedNode.values();
			const selNode = setIter.next().value;
			return (
				<div>
					<PanelHeader highlightNodes={highlightNodes} resetHighlight={resetHighlight} selNode={selNode}></PanelHeader>
					<PanelContent selNode={selNode}></PanelContent>
				</div>
			)
		}
		else {
		  return (
		  	<div>
		  	{
		  		getInitPrompt()
		  	}
		  	</div>
		  )
		}
	}

	return (
		getPanelContent()
	)

}

export default WatchPanel