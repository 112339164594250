import Modal from 'react-bootstrap/Modal';


function PrivacyPolicy(props) {
	return (
		<>
		<Modal
        show={props.show}
        onHide={() => props.setShow(false)}
        dialogClassName="modal-80w"
        aria-labelledby="example-modal-sizes-title-sm">
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-sm">
            Privacy Policy
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <div className="overflow-auto" style={{'max-height': '90vw'}}>
                <p className="lead">This Privacy Policy outlines how our website collects, uses, and safeguards the personal information
                    of its visitors. It specifically addresses the use of Google Analytics, a web analytics service provided by
                    Google, Inc. ("Google"). By using our website, you consent to the practices described in this policy.</p>

                <h3>1. Information Collection and Use</h3>

                <h4>1.1 Log Files and Analytics Data</h4>
                <p>When you visit our website, our servers automatically collect certain information about your visit. This
                    information may include your IP address, browser type, operating system, referring URLs, pages visited, and
                    other similar data. We use this information for statistical purposes, to analyze trends, administer the site,
                    and gather demographic information for aggregate use. This data does not personally identify you.</p>

                <h4>1.2 Google Analytics</h4>
                <p>To enhance our website's functionality and improve user experience, we utilize Google Analytics. Google
                    Analytics uses cookies, which are small text files stored on your device, to collect standard internet log
                    information and visitor behavior information in an anonymous form. The data generated by these cookies about
                    your use of our website (including your IP address) is transmitted to and stored by Google on servers in the
                    United States.</p>
                <p>We use Google Analytics to analyze website usage, compile reports on website activity, and provide other
                    services related to website activity and internet usage. The information collected by Google Analytics is
                    aggregated and does not personally identify you. We do not merge or associate the data collected by Google
                    Analytics with any personally identifiable information.</p>
                <p>You can learn more about how Google Analytics collects and processes data by visiting Google's <a
                        href="https://policies.google.com/technologies/partner-sites">Privacy Policy</a> page.</p>

                <h3>2. Cookies</h3>

                <p>Our website uses cookies to improve your browsing experience. Cookies are small text files stored on your
                    device when you visit a website. We use both session cookies (which expire when you close your browser) and
                    persistent cookies (which stay on your device until deleted) to provide you with a personalized experience
                    and remember certain information for future visits.</p>
                <p>By using our website, you consent to the use of cookies. You can control and manage cookies in your browser
                    settings. However, please note that disabling cookies may impact certain functionalities of the website.</p>

                <h3>3. Third-Party Links</h3>

                <p>Our website may contain links to third-party websites or services. Please note that this Privacy Policy does
                    not apply to those third-party websites or services. We are not responsible for the privacy practices or the
                    content of such websites. We encourage you to read the privacy policies of any third-party websites you
                    visit.</p>

                <h3>4. Data Security</h3>

                <p>We take appropriate security measures to protect against unauthorized access, alteration, disclosure, or
                    destruction of personal information collected through our website. However, please note that no method of
                    transmission over the internet or electronic storage is 100% secure, and we cannot guarantee absolute
                    security.</p>

                <h3>5. Changes to this Privacy Policy</h3>

                <p>We reserve the right to update or modify this Privacy Policy at any time. Any changes will be effective
                    immediately upon posting the revised Privacy Policy on our website. We encourage you to review this Privacy
                    Policy periodically for any updates.</p>

                <h3>6. Contact Us</h3>

                <p>If you have any questions or concerns about this Privacy Policy or our privacy practices, please contact us
                    at christian [at] watchsage [dot] com.</p>

                <p>By continuing to use our website, you acknowledge that you have read and understood this Privacy Policy.</p>
            </div>
        </Modal.Body>
      	</Modal>
        </>
	)
}

export default PrivacyPolicy