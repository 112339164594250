import Card from 'react-bootstrap/Card';

function PanelContent(props){

	function capitalizeFirstLetter(string) {
	  return string.charAt(0).toUpperCase() + string.slice(1);
	}

	function getCaliberContent(selectedNode){
		const keys_1 = ['powerreserve', 'jewels', 'type', 'frequency'];
		const keys_2 = ['diameter', 'hands', 'chronograph', 'astronomical', 'display', 'acoustic'];

		return (
			<>
			<div className="propWrapper">
				<div className="overflow-auto d-flex flex-row flex-nowrap propWrapperChild">
				{
					keys_1.map((key, index) => {
						if (selectedNode[key] != null){
							let str = selectedNode[key];
							if (key === 'diameter') {
								str = str.replace(' mm', 'mm');
							}
							else if (key === 'frequency') {
								str = str.replace(' bph', 'bph');
							}
							else if (key === 'powerreserve') {
								str = str.replace(' h', 'h');
							}
							return (
							<Card className="propCard caseCard" key={selectedNode.name + key + index}>
					        	<Card.Header className="fw-normal">{capitalizeFirstLetter(key)}</Card.Header>
					        	<Card.Body key={selectedNode.name + key + index}>
							        <Card.Text className="fw-light propText">
							          {str}
							        </Card.Text>
						      	</Card.Body>
					    	</Card>
					    )
						}
						else {
							return (<></>);
						}
					})
				}
				</div>
			</div>
			<div className="propWrapper">	
				<div className="overflow-auto d-flex flex-row flex-nowrap propWrapperChild">
				{
					keys_2.map((key, index) => {
						if (selectedNode[key] != null){
							let str = selectedNode[key];
							if (key === 'diameter') {
								str = str.replace(' mm', 'mm');
							}
							else if (key === 'frequency') {
								str = str.replace(' bph', 'bph');
							}
							else if (key === 'powerreserve') {
								str = str.replace(' h', 'h');
							}
							return (
							<Card className="propCard caseCard" key={selectedNode.name + key + index}>
					        	<Card.Header className="fw-normal">{capitalizeFirstLetter(key)}</Card.Header>
					        	<Card.Body key={selectedNode.name + key + index}>
							        <Card.Text className="fw-light propText">
							          {str}
							        </Card.Text>
						      	</Card.Body>
					    	</Card>
					    )
						}
						else {
							return (<></>);
						}
					})
				}
				</div>
			</div>
				{getDescription(selectedNode)}
			</>	
		)
	}

	function getWatchDial(selectedNode){
		const dialNames = {'color': 'Dial Color', 'handstyle': 'Hand', 'indextype': 'Indices'};
		const selDial = selectedNode.hasOwnProperty('dial') ? selectedNode.dial  : null;

		const nameDict = {};
		for (const [key, value] of Object.entries(dialNames)) {
			if (selDial.hasOwnProperty(key)) {
				let str = selDial[key];
				if (key === 'indextype') {
					str = str.replace(' / ', '/');
				}
				nameDict[value] = str;
			}
		}
		return (
			
			<div className="propWrapper">
				<div className="overflow-auto d-flex flex-row flex-nowrap propWrapperChild">
				{
				Object.keys(nameDict).map((key, index) => (
					<Card className="propCard dialCard" key={selectedNode.name + key + index}>
						<Card.Header>{key}</Card.Header>
			      <Card.Body>
			        <Card.Text className="fw-light propText">
			          {nameDict[key]}
			        </Card.Text>
			      </Card.Body>
			    </Card>
					))
				}
				</div>
			</div>
		)
	}

	function getWatchCase(selectedNode){
		const caseNames = {'shape': 'Shape', 'diameter': 'Diameter', 'back': 'Back', 'glass': "Glass", 'lugwidth': 'Lug width', 'height': 'Height'};
		const materialNames = {'material': 'Material'};
		const selCase = selectedNode.hasOwnProperty('case') ? selectedNode.case  : null;

		const nameDict = {};
		if (selCase.hasOwnProperty('materials')) {
			let material_str = "";
			for (const [key, value] of Object.entries(selCase['materials'])) {
				if (key === "0") {
					material_str = value;
				}
				else {
					material_str += ", " + value;
				}
			}
			nameDict[materialNames['material']] = material_str;
		}

		for (const [key, value] of Object.entries(caseNames)) {
			if (selCase.hasOwnProperty(key)) {
				let str = selCase[key];
				if (key === 'diameter' || key === 'lugwidth'|| key === 'height') {
					str = str.replace(' mm', 'mm');
				}
				nameDict[value] = str;
			}
		}

		// More attributes that are not in case dict but should appear under that category
		const keys = {'waterresistance': 'Water resistance', 'gender': "Gender"};
		for (const [attr, name] of Object.entries(keys)) {
			if (selectedNode.hasOwnProperty(attr)) {
				let str = selectedNode[attr];
				let new_name = name;
				if (attr === 'waterresistance') {
					str = str.replace(' m', 'm');
					if (str === '0.00m') {
						continue;
					}
					new_name = 'WR';
				}
				else if (attr === 'gender') {
					if (str === 'X') {
						continue;
					}
				}
				nameDict[new_name] = str;
			}
		}

		return (
			<div className="propWrapper">
				<div className="overflow-auto d-flex flex-row flex-nowrap propWrapperChild">
				{
					Object.keys(nameDict).map((key, index) => (
						<Card className="propCard caseCard" key={selectedNode.name + key + index}>
						<Card.Header>{key}</Card.Header>
				      <Card.Body>
				        <Card.Text className="fw-light propText">
				          {nameDict[key]}
				        </Card.Text>
				      </Card.Body>
				    </Card>
					))
				}
				</div>
			</div>
		)
	}

	function getDescription(selectedNode){
		if (selectedNode.hasOwnProperty('description')) {
			if (selectedNode.description != null) {
				return (
					<div>
						<div style={{'display': 'description' in selectedNode ? 'block' : 'none'}} 
							className="descBox bg-light p-1 rounded-2 my-2 overflow-auto">
							<span className='fw-light'>{selectedNode['description']}</span>
						</div>
					</div>
				)
			}
		}
	}


	function getWatchContent(selectedNode){
		return (
			<div>
				{getWatchCase(selectedNode)}
				{getWatchDial(selectedNode)}
				{getDescription(selectedNode)}
			</div>	
		)	
	}

	const getContent = (selectedNode) => {
		if (selectedNode.obj_type === 'caliber') {
			return getCaliberContent(selectedNode);
		}
		else if (selectedNode.obj_type === 'watch') {
			return getWatchContent(selectedNode);
		}
	}

	return (
		<div>
			{getContent(props.selNode)}
		</div>
	)
}

export default PanelContent