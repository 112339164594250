import all_brands from './all_brands.json';

import ToggleButtonGroup from 'react-bootstrap/ToggleButtonGroup'
//import ToggleButton from 'react-bootstrap/ToggleButton'
import Button from 'react-bootstrap/Button'




function BrandSelector(props) {
  var highlightNodes = props.highlighNodes;

  function selectBrand(value) {
    var brandName = value.target.outerText;
    if (!value.target.checked) {
    let toHighlight = props.graphData.nodes.filter(elem => elem.brand.name === brandName | elem.brand === brandName);
    for (const node of toHighlight) { 
      highlightNodes.add(node);
    }
      value.target.checked = true;
      value.target.classList.add('active');
    }
    else {
      value.target.checked = false;
      value.target.classList.remove('active');

      let toUnHighlight = props.graphData.nodes.filter(elem => elem.brand.name === brandName | elem.brand === brandName);
      for (const node of toUnHighlight) { 
          highlightNodes.delete(node);
        }
    }
  }

  const hotBrands = all_brands.names;

  return (
    <>
      <ToggleButtonGroup type="checkbox" defaultValue={'None'} className="brandGroup" name="options" onChange={selectBrand}>
        {
          hotBrands.map((brand, idx) => {
            // Using a Button instead of a ToggleButton is a work around because when scrolled to the right, 
            // a click would trigger the scrollbar to scroll all the way to the left.
            return <Button onClick={selectBrand} id={"radio_btn_" + brand+ idx} key={"radio_btn_" + brand + idx} value={brand} size='sm' className="text-nowrap btn-secondary brand-btn">{brand}</Button>
          })
        }
      </ToggleButtonGroup>
    </>
  );
}

export default BrandSelector;