import React from 'react';
import WSLogo from './images/logo.svg';
import { useState, useCallback, useRef, useMemo } from 'react';
import SVGComponent from './logo';

import {ForceGraph2D} from 'react-force-graph';
import WatchPanel from './WatchPanel';
import BrandSelector from './BrandSelector';
import SearchBar from './SearchBar';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { SizeMe } from "react-sizeme";

import graphData from './all_calibers_and_watches.json';


function WatchGraph(props) {

  const fgRef = useRef();
  const winHeight = window.innerHeight
  const graphCanvasHeight = winHeight * 0.85;

  const NODE_R = 4;

  const [highlightNodes, setHighlightNodes] = useState(new Set());
  const [selectedNodes, setSelectedNodes] = useState(new Set());
  const [hoverNode, setHoverNode] = useState(null);
  const [selectedNode, setSelectedNode] = useState(null);

  const watchCol = '#a77c4b';
  const calCol = '#333333';
  const selectedBrandRingCol = '#d2a172';

  const updateHighlight = () => {
    setHighlightNodes(highlightNodes);
  };

  const updateSelect = () => {
    setSelectedNodes(selectedNodes);
  };

  const handleNodeHover = node => {   
    setHoverNode(node || null);
    updateHighlight();
  };

  const nodesById = useMemo(() => {
    const nodesById = Object.fromEntries(graphData.nodes.map(node => [node.id, node]));

    // link parent/children
    graphData.nodes.forEach(node => {
      node.childLinks = [];
    });

    graphData.links.forEach(link => {
      if (link.source.hasOwnProperty('obj_type')){
        nodesById[link.source.id].childLinks.push(link)
      }
      else {
        nodesById[link.source].childLinks.push(link)
      }      
    });
    return nodesById;
  }, []);

  const handleNodeClick = (node, event) => {
    console.log(node)
    if (node.obj_type === 'master_caliber'){
      fgRef.current.zoom(1.5, 500);
      fgRef.current.centerAt(node.x, node.y, 500);
      return;
    }

    let updated = false;
    if (node.obj_type === 'caliber') {
      node.childLinks.forEach((link) => {
        let childNode = nodesById[link.target.id];
        if (!childNode.visible){
          // 2 means will be visible but should not be fixed
          childNode.visible = 2;
          link.visible = 1;
          updated = true;
        }
      })
      if (updated){
        // Fix all currently visible nodes such that
        // only the newly added nodes align somewhat when
        // reheating. 
        graphData.nodes.filter(n => n.visible == 1).forEach(node => {
          node.fx = node.x;
          node.fy = node.y;
        });

        // Now set nodes to actually visible
        node.visible = 1;
        node.childLinks.forEach((link) => {
          let childNode = nodesById[link.target.id];
          if (childNode.visible === 2){
            childNode.visible = 1;
          }
        })

        fgRef.current.d3ReheatSimulation();
        //const unfreeze_time = 1750;

        // Remove fixation after 1.75 seconds such that dragging etc. 
        // is enabled again.
        // setTimeout(() => {      
        //   // Unfix all currently visible nodes
        //   graphData.nodes.filter(n => n.visible).forEach(node => {
        //     delete node.fx;
        //     delete node.fy;
        //   }); 
        // }, unfreeze_time);
      }
    }

    fgRef.current.zoom(1.5, 500)
    fgRef.current.centerAt(node.x, node.y, 500)

    //console.log(selectedNodes)
    selectedNodes.clear();
    if (node) {
      selectedNodes.add(node);
    }

    setSelectedNode(node || null);
    updateSelect();
    //console.log(highlightNodes)
  };

  const handleSearchItemClick = node => {
    if (node) {
      let updated = false;

      if (node.obj_type === 'caliber') {
        node.childLinks.forEach((link) => {
          let childNode = nodesById[link.target.id];
          if (!childNode.visible){
            childNode.visible = 1;
            link.visible = 1;
            updated = true;
          }
        })

        //fgRef.current.zoomToFit(500)
        //fgRef.current.centerAt(node.x, node.y, 500);

        if (updated){
          //fgRef.current.d3ReheatSimulation(0.0);
        }
      }
      else {
        let parent = nodesById[node.caliber.id];
        parent.childLinks.forEach((link) => {
          let childNode = nodesById[link.target.id];
          if (!childNode.visible){
            childNode.visible = 1;
            link.visible = 1;
            updated = true;
          }
        })
        if (updated){
          //fgRef.current.d3ReheatSimulation();
        }
      }
      selectedNodes.clear();
      selectedNodes.add(node);

      setSelectedNode(node || null);
      updateSelect();
      //fgRef.current.zoomToFit(500)
      fgRef.current.centerAt(node.x, node.y, 500);
      fgRef.current.zoom(1.5, 750);

    }
  }

  const postRender = (ctx, s) => {
    const rolex_node = nodesById['master_Rolex'];
    const eta_node = nodesById['master_ETA'];
    const breitling_node = nodesById['master_Breitling'];
    const omega_node = nodesById['master_Omega'];
    const iwc_node = nodesById['master_IWC'];
    const pp_node = nodesById['master_Patek Philippe']
    const ap_node = nodesById['master_Audemars Piguet']
    const longines_node = nodesById['master_Longines']
    const glashuette_node = nodesById['master_Glashütte Original']
    const vacheron_node = nodesById['master_Vacheron Constantin']
    const zenith_node = nodesById['master_Zenith']
    const jlc_node = nodesById['master_Jaeger-LeCoultre']

    const rolex_image = document.getElementById("rolex");
    const eta_image = document.getElementById("eta");
    const breitling_image = document.getElementById("breitling");
    const omega_image = document.getElementById("omega");
    const iwc_image = document.getElementById("iwc");
    const pp_image = document.getElementById("pp");
    const ap_image = document.getElementById("ap");
    const longines_image = document.getElementById("longines");
    const glashuette_image = document.getElementById("glashuette");
    const vacheron_image = document.getElementById("vacheron");
    const zenith_image = document.getElementById("zenith");
    const jlc_image = document.getElementById("jlc");


    ctx.globalAlpha = 0.12;
    ctx.drawImage(rolex_image, rolex_node.x - (512/2), rolex_node.y - (292/2), 512, 292);
    ctx.drawImage(eta_image, eta_node.x - (611/2), eta_node.y - (599/2), 611, 599);
    ctx.drawImage(breitling_image, breitling_node.x - (500/2), breitling_node.y - (200/2), 500, 200);
    ctx.drawImage(omega_image, omega_node.x - (318/2), omega_node.y - (169/2), 318, 169);
    ctx.drawImage(iwc_image, iwc_node.x - (512/2), iwc_node.y - (229/2), 512, 229);
    ctx.drawImage(ap_image, ap_node.x - (482.5/2), ap_node.y - (482.5/2), 482.5, 482.5);
    ctx.drawImage(longines_image, longines_node.x - (500/2), longines_node.y - (150/2), 500, 150);
    ctx.drawImage(glashuette_image, glashuette_node.x - (320/2), glashuette_node.y - (150/2), 320, 150);
    ctx.drawImage(vacheron_image, vacheron_node.x - (600/2), vacheron_node.y - (137/2), 600, 137);
    ctx.drawImage(zenith_image, zenith_node.x - (180/2), zenith_node.y - (68/2), 180, 68);
    ctx.drawImage(jlc_image, jlc_node.x - (400/2), jlc_node.y - (104.5/2), 400, 104.5);

    ctx.globalAlpha = 0.3;
    ctx.drawImage(pp_image, pp_node.x - (400/2), pp_node.y - (218/2), 400, 218);
      
    ctx.globalAlpha = 1.0;
  }

  const nodeMode = node => {
    return highlightNodes.has(node) | selectedNodes.has(node) | node === hoverNode ? 'before' :  undefined
  }

  const getNodeRelSize = node => {
    if (node.obj_type === 'master_caliber') {
      return 10;
      
    }
    else{
      return ((nodesById[node.id].childLinks.length + 1) * 1 );
    }
  }

  const paintRing = useCallback((node, ctx) => {
    let radius = Math.sqrt((getNodeRelSize(node) * Math.PI) / 1.3) * NODE_R;

    // add ring just for highlighted nodes
    ctx.beginPath();
    ctx.arc(node.x, node.y, radius, 0, 2 * Math.PI, false);
    ctx.fillStyle = (node === selectedNode) ? '#F1B91895' : (node === hoverNode ? '#d3d3d395' : selectedBrandRingCol);
    ctx.fill();
  }, [hoverNode, selectedNode]);

  const selectWatchOrCaliber = node => {
    if (node){
      highlightNodes.clear();
      highlightNodes.add(node);
    }
    else {
      highlightNodes.clear();
    }
  }

  const getColor = node => {
    if (node.obj_type === 'master_caliber') {
      return '#B76E79'
    }
    if (node.obj_type === 'watch') {
      if (node.hasOwnProperty('gender')) {
        if (node.gender === 'M'){
          return '#1166bb';
        }
        else if (node.gender === 'F'){
          return '#b19cd9'
        }
        else {
          return watchCol;
        }
      }
      else {
        return watchCol;
      }
    }
    else {
      return calCol;
    }
  }

  return (
    <div>
    <div style={{display: 'none'}}>
      <img id="rolex" src="rolex_logo.svg"/>
      <img id="eta" src="eta_logo.svg"/>
      <img id="breitling" src="breitling_logo.svg"/>
      <img id="omega" src="omega_logo.svg"/>
      <img id="iwc" src="iwc_logo.svg"/>
      <img id="pp" src="pp_logo.svg"/>
      <img id="ap" src="ap_logo.svg"/>
      <img id="longines" src="longines_logo.svg"/>
      <img id="glashuette" src="glashuette_logo.svg"/>
      <img id="vacheron" src="vacheron_logo.svg"/>
      <img id="zenith" src="zenith_logo.svg"/>
      <img id="jlc" src="jlc_logo.svg"/>
    </div>
      <Row align-items-start={"True"} className="p-0 m-0">
        <div className="App-header px-2">
            <div style={{'marginTop': 10}}>
                <SVGComponent/>
            </div>
            <Col md={12} className="justify-content-center d-flex header-col p-0 mt-0 mb-2"> 
              <div className="slogan"><span>Explore the Genealogy of Timepieces</span></div>
            </Col>
          <Row className="m-0 gx-3 mb-2">
            <Col md={3} sm={12} className="mt-1">
              <SearchBar data={graphData.nodes} hn={highlightNodes} selectionFunc={handleSearchItemClick} highlightFunc={selectWatchOrCaliber}/>
            </Col>
            <Col md={9} sm={12} className="mt-1">
              <div>
                  <BrandSelector highlighNodes={highlightNodes} graphData={graphData}/>
              </div>
            </Col>
          </Row>
        </div>
      </Row>
      <Row className='p-0 m-0 flex-row-reverse'>
        <Col sm={12} md={9} className="p-0 m-0">
            <SizeMe>
            {({ size: { width } }) => 
            <ForceGraph2D
                backgroundColor={'#F5F5F5'}
                ref={fgRef}
                graphData={graphData}
                nodeLabel={(node) => node.name.split('/')[0]}
                nodeColor={(node) => getColor(node)}
                linkDirectionalParticles={2}
                nodeCanvasObjectMode={nodeMode}
                nodeCanvasObject={paintRing}
                onNodeClick={handleNodeClick}
                onNodeHover={handleNodeHover}
                enableNodeDrag={false}
                nodeVisibility={(node) => node.visible}
                linkVisibility={(link) => link.visible}
                linkWidth={1}
                nodeRelSize={NODE_R}
                nodeVal={(node) => getNodeRelSize(node)}
                maxZoom={1.5}
                minZoom={0.1}
                dagMode={'zout'}
                width={width}
                height={graphCanvasHeight}
                onEngineStop={() => {      
                  // Unfix all currently visible nodes
                  graphData.nodes.filter(n => n.visible).forEach(node => {
                    delete node.fx;
                    delete node.fy;
                  })
                }}
                onRenderFramePost={(c,s)=>postRender(c,s)}
                /> 
              }
              </SizeMe>
          </Col>
          <Col sm={12} md={3} className="panel ps-2 pe-2 m-0" style={{'maxHeight': graphCanvasHeight}} >
            <WatchPanel selectedNode={selectedNodes} graphData={graphData} highlightNodes={highlightNodes}/>
          </Col>
        </Row>
      </div>  
  );
}
/**/
export default WatchGraph;
