import wb_logo from'./images/wb_logo.png';
import cc_logo from'./images/cc_logo.png';


function PanelHeader(props) {

	function getYearLimited(watch) {
		let finalStr = ''
		let hasReleased = false;
		let hasStopped = false;
		if (watch.hasOwnProperty('released')) {
			let released = watch.released.split('-')[0];
			if (released !== ''){
				finalStr += watch.released.split('-')[0];
				hasReleased = true;
			}
		}
		if (watch.hasOwnProperty('stopped')) {
			let stopped = watch.stopped.split('-')[0];
			if (stopped !== ''){
				if (hasReleased){
					finalStr += ' - ' + watch.stopped.split('-')[0];
				}
				else {
					finalStr = stopped
				}
				hasStopped = true;
			}
		}
		if (watch.hasOwnProperty('limited_nr')) {
			if (finalStr === '') {
				finalStr = 'Limited: ' + watch.limited_nr;
			}
			else {
				finalStr += ' | Limited: ' + watch.limited_nr;
			}
		}
		let prefix = (hasReleased && !hasStopped) ? 'Released: ' : ((hasReleased && hasStopped) ? 'Produced: ':  ((!hasReleased && hasStopped) ? 'Discontinued: ': ''))
		if (finalStr !== '') {
			return ' | ' + prefix + finalStr;
		}
		else {
			return '';
		}
	}

	const getCCLink = (cc_link) => {
		if (cc_link) {
			return (
				<p className="panelText">
					<img src={cc_logo} className="wbLogo me-1" alt="WatchBase logo"></img>
					<a className="fw-light ccLink panelText" target="_blank" rel="noreferrer" href={cc_link}>View at CaliberCorner</a>
				</p>
			)
		}
	}


	const getCaliberHeader = (brand, ref_num, cc_link) => {
		return (
			<div className="watchHeader mt-2">
				<p className="panelHeader"><span className="fw-bold panelTitle" onMouseOver={props.highlightNodes} onMouseOut={props.resetHighlight}> {brand} </span></p>
				<p className="panelSubtitle"><span className="fw-light panelSubtitle"> {"Caliber number: " + ref_num} </span> </p>
				{getCCLink(cc_link)}
				<hr className="breakLine"/>
			</div>
		)
	}

	const getWatchHeader = (watch) => {

		return (
			<div className="watchHeader mt-2">
				<p className="panelHeader"><span className="fw-bold panelTitle">{watch.brand.name}</span></p>
				<p className="panelHeadContentWrapper">
					<p className="panelText"><span className="fw-bolder panelText"> {watch.name.replaceAll(' / ', '/' )} </span></p>
				</p>
				<p className="panelSubtitle">
					<span className="fw-light panelSubtitle"> {"Ref.: " + watch.reference_number + getYearLimited(watch)}</span>				</p>
				<p className="panelText">
					<img src={wb_logo} className="wbLogo me-1" alt="WatchBase logo"></img>
					<a className="fw-light wbLink panelSubtitle" target="_blank" rel="noreferrer" href={"https://watchbase.com/search#q=" + watch.reference_number}>Find on WatchBase</a>
				</p>

				<hr className="breakLine"/>
			</div>
		)
	}

	const getHeader = (selectedNode) => {
		if (selectedNode.obj_type === 'caliber') {
			return getCaliberHeader(selectedNode.brand, selectedNode.reference_number, selectedNode.cc_link);
		}
		else if (selectedNode.obj_type === 'watch') {
			return getWatchHeader(selectedNode);
		}
	}

	return (
		<div>
			{getHeader(props.selNode)}
		</div>
	)
}

export default PanelHeader